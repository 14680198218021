import React, { useState, useRef, useEffect } from "react";
import { navigate } from "@reach/router";
import theme from "../theme.js";

// Atoms
import { useRecoilState } from "recoil";
import { accountsAtom, currentAccountAtom } from "./atoms.js";

// Material
import {
	AppBar,
	Avatar,
	Box,
	ClickAwayListener,
	Divider,
	Drawer,
	Grow,
	IconButton,
	List,
	makeStyles,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Toolbar,
	Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";

// Icons
import Menu from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
	FlashOn,
	Home,
	Settings,
	Equalizer,
	// Bookmarks
} from "@material-ui/icons";
import { blue, purple } from "@material-ui/core/colors";

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
	scalp: {
		background: "linear-gradient(10deg, #000 40%, red)",
	},
	purple: {
		color: theme.palette.getContrastText(purple[100]),
		backgroundColor: purple[100],
	},
	blue: {
		color: theme.palette.getContrastText(blue[100]),
		backgroundColor: blue[100],
	},
	root: {
		display: "flex",
	},
	appBar: {
		// zIndex: theme.zIndex.drawer + 1,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 10,
		color: "white",
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		background: "linear-gradient(230deg, #000 40%, red)",
		color: "#fff",
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(7) + 1,
		},
		background: "linear-gradient(230deg, #000 40%, red)",
		color: "white",
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		backgroundColor: "#000",
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	icon: {
		color: "white",
	},
}));

export default function Header() {
	//////// Styles
	const classes = useStyles();

	//////// Drawer State
	const [openDrawer, setOpenDrawer] = useState(false);
	const handleDrawerOpen = () => {
		setOpenDrawer(true);
	};
	const handleDrawerClose = () => {
		setOpenDrawer(false);
	};

	//////// Account State
	const [currentAccount, setCurrentAccount] =
		useRecoilState(currentAccountAtom);
	const [accounts, setAccounts] = useRecoilState(accountsAtom);
	const handleAccountChange = (index) => {
		var accountsCopy = accounts.map((a) => ({ ...a }));
		accountsCopy.forEach(function (obj) {
			if (obj.key === index) {
				obj.selected = true;

				// Set Current Account
				var objCopy = { ...obj };
				delete objCopy.selected;
				setCurrentAccount(objCopy);
			} else {
				obj.selected = false;
			}
		});
		setAccounts(accountsCopy);
	};

	//////// Account Menu State
	const anchorRef = useRef(null);
	const [openAccountMenu, setOpenAccountMenu] = useState(false);
	const prevOpenAccountMenu = useRef(openAccountMenu);
	const handleAccountMenuToggle = () => {
		setOpenAccountMenu((prevOpenAccountMenu) => !prevOpenAccountMenu);
	};
	const handleCloseAccountMenu = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpenAccountMenu(false);
		handleAccountChange(event);
	};
	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenAccountMenu(false);
		}
	}
	useEffect(() => {
		if (
			setOpenAccountMenu.current === true &&
			prevOpenAccountMenu === false
		) {
			anchorRef.current.focus();
		}
		setOpenAccountMenu.current = prevOpenAccountMenu;
	}, [prevOpenAccountMenu]);

	return (
		<div>
			<AppBar
				position='fixed'
				className={clsx(classes.appBar, {
					[classes.appBarShift]: openDrawer,
				})}>
				<Toolbar className={classes.scalp}>
					<Box
						display='flex'
						width='100%'
						alignItems='center'
						justifyContent='space-between'>
						<Box display='flex' alignItems='center'>
							<Box ml={-1}>
								<IconButton
									aria-label='open drawer'
									onClick={handleDrawerOpen}
									edge='start'
									className={clsx(classes.menuButton, {
										[classes.hide]: openDrawer,
									})}>
									<Menu />
								</IconButton>
							</Box>
							<Box>
								<img
									alt='main-logo'
									src='/g.png'
									style={{
										maxWidth: "30px",
										paddingLeft: "10px",
										paddingRight: "30px",
									}}
								/>
							</Box>
						</Box>
						<Box></Box>
						<Box display='flex' alignItems='center'>
							<Typography>{currentAccount.name}</Typography>
							<Box ml={2}>
								<Avatar
									ref={anchorRef}
									aria-controls={
										openAccountMenu ? "account-list-grow" : undefined
									}
									aria-haspopup='true'
									onClick={handleAccountMenuToggle}
									style={{ cursor: "pointer" }}
									className={
										currentAccount.avatar === "K"
											? classes.purple
											: classes.blue
									}>
									{currentAccount.avatar}
								</Avatar>
								<Popper
									open={openAccountMenu}
									anchorEl={anchorRef.current}
									role={undefined}
									transition
									disablePortal>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{
												transformOrigin:
													placement === "bottom"
														? "center top"
														: "center bottom",
											}}>
											<Paper>
												<ClickAwayListener
													onClickAway={handleCloseAccountMenu}>
													<MenuList
														autoFocusItem={openAccountMenu}
														id='account-list-grow'
														onKeyDown={handleListKeyDown}>
														{accounts.map((item, i) => (
															<MenuItem
																key={item.key}
																id={item.key}
																selected={item.selected}
																onClick={(e) =>
																	handleCloseAccountMenu(
																		e.target.id
																	)
																}>
																{item.name}
															</MenuItem>
														))}
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</Box>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				variant='permanent'
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: openDrawer,
					[classes.drawerClose]: !openDrawer,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: openDrawer,
						[classes.drawerClose]: !openDrawer,
					}),
				}}>
				<div className={classes.toolbar}>
					<IconButton
						onClick={handleDrawerClose}
						style={{ color: "#fff" }}>
						{theme.direction === "rtl" ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</div>
				<Divider />
				<List>
					<ListItem button key='dashboard' onClick={() => navigate(`/`)}>
						<ListItemIcon className={classes.icon}>
							<Home />
						</ListItemIcon>
						<ListItemText primary='Dashboard' />
					</ListItem>
					<ListItem button key='trade' onClick={() => navigate(`/trade`)}>
						<ListItemIcon className={classes.icon}>
							<FlashOn />
						</ListItemIcon>
						<ListItemText primary='Trade' />
					</ListItem>
					{/* <ListItem button key='stats' onClick={() => navigate(`/stats`)}>
            <ListItemIcon className={classes.icon}><Equalizer /></ListItemIcon>
            <ListItemText primary='Stats' />
        </ListItem> */}
					<ListItem
						button
						key='settings'
						onClick={() => navigate(`/settings`)}>
						<ListItemIcon className={classes.icon}>
							<Settings />
						</ListItemIcon>
						<ListItemText primary='Settings' />
					</ListItem>
					{/* <ListItem button key='material' onClick={() => navigate(`/style`)}>
            <ListItemIcon className={classes.icon}><Bookmarks /></ListItemIcon>
            <ListItemText primary='Material' />
        </ListItem> */}
				</List>
			</Drawer>
		</div>
	);
}
