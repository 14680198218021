import axios from 'axios'
import {GetWebhookUrlFromConfig} from './helper'
import {set, subDays} from 'date-fns'

export default function Nada() {
	return ''
}

///
/// Alpaca
///
export async function AlpacaExecuter(
	commander,
	commandBody,
	configKey,
	isReport
) {
	console.log(
		`Executioner for [${configKey}] to perform "${commander}"`,
		commandBody
	)

	const commandEndpoint = isReport ? 'alpacaReport' : 'alpacaCommand'
	const path = GetWebhookUrlFromConfig(configKey)

	if (path === '') return

	axios({
		method: 'post',
		url: `${path}${commandEndpoint}`,
		data: commandBody
	})
}

export async function AlpacaResponsiveExecuter(
	commander,
	commandBody,
	configKey,
	isReport
) {
	let execPromise = new Promise(function (res, rej) {
		console.log(`Executioner for [${configKey}] to perform "${commander}"`)

		const commandEndpoint = isReport ? 'alpacaReport' : 'alpacaCommand'
		const path = GetWebhookUrlFromConfig(configKey)

		if (path === '') {
			res('Error: No path was returned.')
			return
		}

		axios({
			method: 'post',
			url: `${path}${commandEndpoint}`,
			data: commandBody
		})
			.then((data) => {
				res(data.data)
			})
			.catch((err) => {
				res(err)
			})
	})

	return execPromise
}
/// ///

///
/// Cache
///
export async function CacheExecuter(commander, commandBody, configKey) {
	let execPromise = new Promise(function (res, rej) {
		console.log(
			`Executioner for [${configKey}] to perform "${commander}"`,
			commandBody
		)

		const path = GetWebhookUrlFromConfig(configKey)

		if (path === '') {
			res('Error: No path was returned.')
			return
		}

		axios({
			method: 'post',
			url: `${path}${commander}`,
			data: commandBody
		})
			.then((data) => {
				res(data.data)
			})
			.catch((err) => {
				res(err)
			})
	})

	return execPromise
}

export async function getKevinsExecutions(currentAccount) {
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			d: {
				c: 'presetGetCommands'
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.path}/alpacaCommand_responsive`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function sleep(time) {
	return new Promise((resolve) => setTimeout(resolve, time))
}

export async function syncReport(currentAccount) {
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			parameters: {
				command: 'syncReport'
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.repPath}/alpacaReport`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

function getTimeframe(timeframe, selectedStartDate, selectedEndDate) {
	let start, end
	const currentDate = new Date()
	switch (timeframe) {
		case 'All':
			start = new Date(Date.UTC(2021, 0, 1, 0, 0, 0)).toISOString()
			end = new Date().toISOString()
			break
		case 'Today':
			start = new Date(
				Date.UTC(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate(),
					0
				)
			).toISOString()
			end = new Date().toISOString()
			break
		case 'Yesterday':
			start = subDays(
				new Date(
					Date.UTC(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate(),
						0
					)
				),
				1
			).toISOString()
			end = subDays(
				new Date(
					Date.UTC(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate(),
						23,
						59,
						59
					)
				),
				1
			).toISOString()
			break
		case 'Week':
			start = subDays(
				new Date(
					Date.UTC(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate(),
						0
					)
				),
				(currentDate.getDay() - 1 + 7) % 7
			).toISOString() // [Todays Date] - (([Current Day of Week] - [Day of Week to find] + 7) modulus divide 7)
			end = new Date().toISOString()
			break
		case 'Last 7':
			start = subDays(
				new Date(
					Date.UTC(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate(),
						0
					)
				),
				7
			).toISOString()
			end = new Date().toISOString()
			break
		case 'Month':
			start = new Date(
				Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1, 0)
			).toISOString()
			end = new Date().toISOString()
			break
		case 'Last 28':
			start = subDays(
				new Date(
					Date.UTC(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate(),
						0
					)
				),
				28
			).toISOString()
			end = new Date().toISOString()
			break
		case 'YTD':
			start = new Date(
				Date.UTC(currentDate.getFullYear(), 0, 1, 0)
			).toISOString()
			end = new Date().toISOString()
			break
		case 'Last 365':
			start = subDays(
				new Date(
					Date.UTC(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate(),
						0
					)
				),
				365
			).toISOString()
			end = new Date().toISOString()
			break
		case 'Custom':
			start = new Date(
				Date.UTC(
					selectedStartDate.getFullYear(),
					selectedStartDate.getMonth(),
					selectedStartDate.getDate(),
					selectedStartDate.getDate(),
					selectedStartDate.getHours(),
					selectedStartDate.getMinutes(),
					selectedStartDate.getSeconds(),
					0
				)
			).toISOString()
			end = new Date(
				Date.UTC(
					selectedEndDate.getFullYear(),
					selectedEndDate.getMonth(),
					selectedEndDate.getDate(),
					selectedEndDate.getDate(),
					selectedEndDate.getHours(),
					selectedEndDate.getMinutes(),
					selectedEndDate.getSeconds(),
					0
				)
			).toISOString()
			break
		default:
			break
	}
	return {
		start,
		end
	}
}

export async function getAggSymbols(
	currentAccount,
	timeframe,
	selectedStartDate,
	selectedEndDate,
	sortCol,
	tableDataType,
	ourRequest
) {
	let tF = getTimeframe(timeframe, selectedStartDate, selectedEndDate)
	let execPromise = new Promise(function (res, rej) {
		var data
		if (tableDataType === 'All') {
			data = JSON.stringify({
				k: currentAccount.key,
				parameters: {
					command: 'callDBQuery',
					query: `CALL GetAggregateSymbols('${currentAccount.key}', '', '${tF.start}', '${tF.end}', '-7');`
				}
			})
		} else if (tableDataType === 'Positions' || tableDataType === 'Orders') {
			data = JSON.stringify({
				k: currentAccount.key,
				parameters: {
					command: 'getOpenSymbols'
				}
			})
		}
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.repPath}/alpacaReport`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data,
			cancelToken: ourRequest.token
		}
		axios(config)
			.then((response) => {
				var preSort = response.data
				if (tableDataType === 'All') {
					preSort = formatMe(preSort)
					preSort = sortMe(preSort, sortCol)
				} else if (tableDataType === 'Positions') {
					// preSort = {
					// 	OpenPositions: [
					// 		{
					// 			Symbol: "AAPL",
					// 			Quantity: 1,
					// 			Average: 145.96,
					// 			CurrentPrice: 145.97,
					//			Diff: CurrentPrice - Average
					//			PnLPercent: (CurrentPrice - Average) / Average
					// 			Value: 145.96,
					// 			Return: 0.01,
					// 		},
					// 	],
					// };
					preSort = sortMe(preSort.OpenPositions, sortCol)
				} else if (tableDataType === 'Orders') {
					// preSort = {
					// 	OpenOrders: [
					// 		{
					// 			Symbol: "AMZN",
					// 			Quantity: 1,
					// 			Average: 10,
					// 			Value: 10,
					// 		},
					// 	],
					// };
					preSort = sortMe(preSort.OpenOrders, sortCol)
				}
				console.log(preSort)
				res(preSort)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

function formatMe(d) {
	d.forEach((r) => {
		r.Return = roundTenth(r.BalancedActualAmount)
		r.Success = roundTenthPercent(r.FilledBuyOrderCount / r.AllBuyOrderCount)
		r.BuyDiff =
			Math.round(
				(r.BuyActualAveragePrice - r.BuyAskingAveragePrice) * 1000
			) / 1000
		r.SellDiff =
			Math.round(
				(r.SellActualAveragePrice - r.SellAskingAveragePrice) * 1000
			) / 1000
		r.BalancedAskingAveragePrice = roundTenth(r.BalancedAskingAveragePrice)
		r.BalancedActualAveragePrice = roundTenth(r.BalancedActualAveragePrice)
	})
	return d
}

export function sortMe(d, sortCol) {
	switch (sortCol.col) {
		case 'Ticker':
			sortCol.desc
				? d.sort((a, b) => (a.Symbol < b.Symbol ? -1 : 1))
				: d.sort((a, b) => (a.Symbol > b.Symbol ? -1 : 1))
			break
		case 'Return':
			sortCol.desc
				? d.sort((a, b) =>
						a.BalancedActualAmount > b.BalancedActualAmount ? -1 : 1
				  )
				: d.sort((a, b) =>
						a.BalancedActualAmount < b.BalancedActualAmount ? -1 : 1
				  )
			break
		case 'Orders':
			sortCol.desc
				? d.sort((a, b) =>
						a.AllBuyOrderCount > b.AllBuyOrderCount ? -1 : 1
				  )
				: d.sort((a, b) =>
						a.AllBuyOrderCount < b.AllBuyOrderCount ? -1 : 1
				  )
			break
		case 'Success':
			sortCol.desc
				? d.sort((a, b) => (a.Success > b.Success ? -1 : 1))
				: d.sort((a, b) => (a.Success < b.Success ? -1 : 1))
			break
		case 'Ask':
			sortCol.desc
				? d.sort((a, b) =>
						a.BalancedAskingAveragePrice > b.BalancedAskingAveragePrice
							? -1
							: 1
				  )
				: d.sort((a, b) =>
						a.BalancedAskingAveragePrice < b.BalancedAskingAveragePrice
							? -1
							: 1
				  )
			break
		case 'Real':
			sortCol.desc
				? d.sort((a, b) =>
						a.BalancedActualAveragePrice > b.BalancedActualAveragePrice
							? -1
							: 1
				  )
				: d.sort((a, b) =>
						a.BalancedActualAveragePrice < b.BalancedActualAveragePrice
							? -1
							: 1
				  )
			break
		case 'BuyDiff':
			sortCol.desc
				? d.sort((a, b) => (a.BuyDiff > b.BuyDiff ? -1 : 1))
				: d.sort((a, b) => (a.BuyDiff < b.BuyDiff ? -1 : 1))
			break
		case 'SellDiff':
			sortCol.desc
				? d.sort((a, b) => (a.SellDiff > b.SellDiff ? -1 : 1))
				: d.sort((a, b) => (a.SellDiff < b.SellDiff ? -1 : 1))
			break
		case 'Qty':
			sortCol.desc
				? d.sort((a, b) => (a.Quantity > b.Quantity ? -1 : 1))
				: d.sort((a, b) => (a.Quantity < b.Quantity ? -1 : 1))
			break
		case 'Avg':
			sortCol.desc
				? d.sort((a, b) => (a.Average > b.Average ? -1 : 1))
				: d.sort((a, b) => (a.Average < b.Average ? -1 : 1))
			break
		case 'Value':
			sortCol.desc
				? d.sort((a, b) => (a.Value > b.Value ? -1 : 1))
				: d.sort((a, b) => (a.Value < b.Value ? -1 : 1))
			break
		case 'Price':
			sortCol.desc
				? d.sort((a, b) => (a.Price > b.Price ? -1 : 1))
				: d.sort((a, b) => (a.Price < b.Price ? -1 : 1))
			break
		case 'Diff':
			sortCol.desc
				? d.sort((a, b) => (a.Price > b.Price ? -1 : 1))
				: d.sort((a, b) => (a.Price < b.Price ? -1 : 1))
			break
		case 'PnLPercent':
			sortCol.desc
				? d.sort((a, b) => (a.Price > b.Price ? -1 : 1))
				: d.sort((a, b) => (a.Price < b.Price ? -1 : 1))
			break
	}
	return d
}

export async function buildTodaysTrades(currentAccount, d) {
	var data = JSON.parse(d)
	var todaysTradesPrep = []
	for (const ticker of data) {
		await getSymbolConfig(currentAccount, ticker).then((d) => {
			var newStock = {}
			newStock.key = ticker
			newStock.ticker = ticker
			newStock.strat = ticker + '_ASA3.0'
			newStock.live = false
			newStock.selected = false
			newStock.poly = false
			newStock.setup = 'Morning Setup'
			newStock.canSell = d.PositionCanSell
			newStock.canBuy = d.PositionCanBuy
			newStock.openOnly = d.OnlyOpenPositionsAllowed
			newStock.max = d.PositionTotalAllocationAmount
			newStock.init = d.PositionInitialAmount
			newStock.hold = d.PositionHoldAmount
			newStock.seg = d.PositionSegmentedAmount
			newStock.buyT = d.BuyTimeout
			newStock.sellT = d.SellTimeout
			newStock.take = d.PositionMinProfitPercent
			newStock.stop = d.PositionStopPricePercent
			newStock.water = d.PositionAverageDownBufferPercent
			todaysTradesPrep.push(newStock)
		})
	}
	todaysTradesPrep[0].selected = true
	return todaysTradesPrep
}

async function getSymbolConfig(currentAccount, t) {
	let execPromise = new Promise(function (res, rej) {
		var data = JSON.stringify({
			k: currentAccount.key,
			d: {
				c: 'getSymbolConfig',
				s: t
			}
		})

		var config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.path}/alpacaCommand_responsive`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				console.log('Get Config')
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function getTodaysTraded(currentAccount) {
	var start = set(new Date(), {
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0
	}).toISOString()
	var end = new Date().toISOString()
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			parameters: {
				command: 'callDBQuery',
				query: `CALL GetSymbols('${currentAccount.key}', '${start}', '${end}', '-7');`
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.repPath}/alpacaReport`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data[0].Symbols)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function getAccount(currentAccount) {
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			parameters: {
				command: 'getAccount'
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.repPath}/alpacaReport`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export function toUSD(d) {
	var formattedNumber = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	}).format(d)
	return formattedNumber
}

export function toNum(d) {
	var formattedNumber = new Intl.NumberFormat('en-US').format(d)
	return formattedNumber
}

export function roundTenth(d) {
	var formattedNumber = Math.round(d * 100) / 100
	return formattedNumber
}

export function roundTenthPercent(d) {
	var formattedNumber = Math.round(d * 10000) / 100
	return formattedNumber
}

export async function getAggRange(
	currentAccount,
	timeframe,
	selectedStartDate,
	selectedEndDate
) {
	let tF = getTimeframe(timeframe, selectedStartDate, selectedEndDate)
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			parameters: {
				command: 'callDBQuery',
				query: `CALL GetAggregateAll('${currentAccount.key}', '${tF.start}', '${tF.end}', '-7');`
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.repPath}/alpacaReport`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data[0])
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function sellAllSymbol(currentAccount, ticker) {
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			d: {
				c: 'sellAllSymbol',
				s: ticker
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.path}/alpacaCommand`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function closeOpenOrders(currentAccount, ticker) {
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			d: {
				c: 'cancelAllSymbol',
				s: ticker
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.path}/alpacaCommand`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function updateSymbolConfig(
	currentAccount,
	ticker,
	currentConfig
) {
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			d: {
				c: 'updateSymbolConfig',
				s: ticker,
				i: {
					PositionTotalAllocationAmount: currentConfig.max,
					PositionInitialAmount: currentConfig.init,
					PositionHoldAmount: currentConfig.hold,
					PositionSegmentedAmount: currentConfig.seg,
					PositionStopPricePercent: currentConfig.stop,
					PositionAverageDownBufferPercent: currentConfig.water,
					PositionMinProfitPercent: currentConfig.take,
					PositionCanSell: currentConfig.canSell,
					PositionCanBuy: currentConfig.canBuy,
					BuyTimeout: currentConfig.buyT,
					SellTimeout: currentConfig.sellT,
					OnlyOpenPositionsAllowed: currentConfig.openOnly,
					PositionIsLocked: currentConfig.isLocked
				}
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.path}/alpacaCommand`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function getTodayOrders(currentAccount) {
	var orders = {}

	// Get Today's Orders
	var start = set(new Date(), {
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0
	}).toISOString()
	var end = new Date().toISOString()
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			parameters: {
				command: 'callDBQuery',
				query: `CALL GetAggregateAll('${currentAccount.key}', '${start}', '${end}', '-7');`
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.repPath}/alpacaReport`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				if (response.data[0].AllBuyOrderCount) {
					orders.today = response.data[0].AllBuyOrderCount
					orders.todayPercent = roundTenthPercent(
						response.data[0].FilledBuyOrderCount /
							response.data[0].AllBuyOrderCount
					)
				} else {
					orders.today = 0
					orders.todayPercent = 0
				}
				res(orders)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function getAllOrders(currentAccount) {
	var orders = {}

	// Get All Orders
	var start = set(new Date(), {
		year: 2021,
		month: 1,
		date: 1,
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0
	}).toISOString()
	var end = new Date().toISOString()
	let execPromise = new Promise(function (res, rej) {
		const data2 = JSON.stringify({
			k: currentAccount.key,
			parameters: {
				command: 'callDBQuery',
				query: `CALL GetAggregateAll('${currentAccount.key}', '${start}', '${end}', '-7');`
			}
		})
		const config2 = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.repPath}/alpacaReport`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data2
		}
		axios(config2)
			.then((response) => {
				if (response.data[0].AllBuyOrderCount) {
					orders.all = response.data[0].AllBuyOrderCount
					orders.allPercent = roundTenthPercent(
						response.data[0].FilledBuyOrderCount /
							response.data[0].AllBuyOrderCount
					)
				} else {
					orders.all = 0
					orders.allPercent = 0
				}
				res(orders)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}

export async function executeManualOrder(
	currentAccount,
	currentConfig,
	orderDetails,
	limitOrder,
	isForced,
	buyOrSell
) {
	let type = limitOrder ? 'limit' : 'market'
	let orderType = buyOrSell === 'Buy' ? 'buySymbol' : 'sellSymbol'
	let execPromise = new Promise(function (res, rej) {
		const data = JSON.stringify({
			k: currentAccount.key,
			d: {
				c: orderType,
				s: currentConfig.ticker,
				type: type,
				tif: orderDetails.tif,
				p: orderDetails.limit,
				q: orderDetails.qty,
				f: isForced
			}
		})
		const config = {
			method: 'post',
			url: `${currentAccount.url}${currentAccount.path}/alpacaCommand`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		}
		axios(config)
			.then((response) => {
				res(response.data)
			})
			.catch((error) => {
				rej(error)
			})
	})
	return execPromise
}
