import {Box, Container, Collapse} from '@material-ui/core'
import Lottie from 'react-lottie'
import animationData from '../../../resources/Guillotine Lottie.json'
import logo from '../../../resources/g.png'
import {useRecoilValue} from 'recoil'
import {currentAccountAtom} from '../../atoms'
import {useState, useEffect} from 'react'
import {sleep, syncReport} from '../../../actions/actions'
import {Router} from '@reach/router'
import Dashboard from '../dashboard/Dashboard'
import Trade from '../trade/Trade'
import Stats from '../stats/Stats'
import Settings from '../settings/Settings'
import Header from '../../Header'

export default function Loading() {
	const currentAccount = useRecoilValue(currentAccountAtom)
	const [isLoaded, setisLoaded] = useState(false)
	const [isReady, setisReady] = useState(false)
	const [go, setGo] = useState(false)
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}

	// document.addEventListener("keydown", logKey);
	document.addEventListener('keydown', (event) => {
		if (event.keyCode === 71) {
			setisReady(true)
			// console.log("Ready");
			setisLoaded(true)
			// console.log("Loaded");
		}
	})

	useEffect(() => {
		sleep(3000).then(() => {
			setisReady(true)
			// console.log("Ready");
		})
		syncReport(currentAccount).then(() => {
			setisLoaded(true)
			// console.log("Loaded");
		})
	}, [currentAccount])

	useEffect(() => {
		const initInterval = setInterval(() => {
			if (isLoaded && isReady) {
				setGo(true)
				console.log('Go!')
				clearInterval(initInterval)
			} else {
				console.log("Don't Go")
			}
		}, 100)
		return () => clearInterval(initInterval)
	}, [currentAccount, isReady, isLoaded])

	return (
		<div>
			<Collapse in={!go}>
				<Container>
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						height='99vh'>
						<Lottie options={defaultOptions} height={250} width={250} />
					</Box>
				</Container>
			</Collapse>
			<div className={isLoaded ? 'showMe' : 'hideMe'}>
				<div className='watermark' />
				<Header />
			</div>
			<div className={isLoaded ? '' : 'gone'}>
				<Router>
					<Dashboard default path='/' />
					<Trade path='trade' />
					<Stats path='stats' />
					<Settings path='settings' />
					{/* <MaterialUI path='style'/> */}
				</Router>
			</div>
		</div>
	)
}
