import Header from "./modules/Header";
import Stats from "./modules/pages/stats/Stats";
import Trade from "./modules/pages/trade/Trade";
import Settings from "./modules/pages/settings/Settings";
import React, { useState } from "react";
import { useEffect } from "react";
import Dashboard from "./modules/pages/dashboard/Dashboard";
import { RecoilRoot, useRecoilValue } from "recoil";
import { Router } from "@reach/router";
// import MaterialUI from './modules/pages/misc/MaterialUI'
import { SnackbarProvider } from "notistack";

import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { makeStyles } from "@material-ui/core";
import { syncReport, sleep, tester } from "./actions/actions";
import { currentAccountAtom } from "./modules/atoms";
import Loading from "./modules/pages/loading/Loading";

const useStyles = makeStyles({
	root: {
		top: 80,
	},
});

export default function App(styles) {
	const classes = useStyles();

	return (
		<RecoilRoot>
			<SnackbarProvider
				classes={{
					containerRoot: classes.root,
				}}
				maxSnack={5}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}>
				<LocalizationProvider dateAdapter={DateFnsUtils}>
					<Loading />
				</LocalizationProvider>
			</SnackbarProvider>
		</RecoilRoot>
	);
}

// export default App;
